//!!alias widgets/helpers/array

export function remove(arr, value) {
    var index = arr.indexOf(value);
    if (index >= 0)
        arr.splice(index, 1);
}

export function of(arr_like) {
    var result = [];
    for (var i = 0; i<arr_like.length; i++) {
        result.push(arr_like[i]);
    }
    return result;
}

export function isArray(arr) {
    if (typeof Array.isArray === "function")
        return Array.isArray(arr);

    return Object.prototype.toString.call(arr) === '[object Array]';
}
