<template>
    <input type="text"></input>
</template>
<script>
//!!alias widgets/inputs/date
import $ from 'tm:jQuery';
import 'tm:bootstrap';

import {bound} from 'tm:widgets/helpers/etc';

export default {
    template: '<input type="text">',
    props: ['value', 'format'],

    mounted: function(){
        $(this.$el).datepicker({
            format: this.format,
            onSelect: bound(this, this.updateDate),
            onClose: bound(this, this.updateDate)
        });
        this.setDate();
    },

//    beforeDestroy: function() {
//        $(this.$el).datepicker("destroy");
//    },

    methods: {
        setDate: function setDate() {
            $(this.$el).datepicker("setDate", !!this.value ? new Date(this.value) : null);
        },

        updateDate: function updateDate(string) {
            if (string.length === 0)
                this.$emit('input', null);

            let parts = string.split('.');
            this.$emit('input', parts[2] + "-" + parts[1] + '-' + parts[0]);
        }
    },

    watch: {
        value: function value() {
            this.setDate();
        }
    }
}
    
</script>
