//!!alias widgets/vault/local

class LocalVaultNamespace {
    constructor(vault) {
        this.vault = vault;
    }

    async find(key) {
        var value = this.vault.storage.getItem(key);
        if (value === null)
            return null;

        return JSON.parse(value);
    }

    async set(key, value) {
        this.vault.storage.setItem(key, JSON.stringify(value));
    }
}

export default class LocalVault {
    constructor(name, storage) {
        this.name = name;
        this.storage = storage;
    }

    get_namespace(name) {
        if (name !== this.name) return null;
        return new LocalVaultNamespace(this);
    }
}
