//!!alias widgets/helpers/promise
import $ from 'tm:jQuery';

export class PromiseDelegate {
    constructor() {
        this._resolve = null;
        this._reject = null;
        this._promise = new Promise((rs, rj) => {
            this._resolve = rs;
            this._reject = rj;
        });
    }

    promise() {
        return this._promise;
    }

    resolve(...params) {
        return this._resolve(...params);
    }

    reject(...params) {
        return this._reject(...params);
    }

    mirror(thennable) {
        thennable.then(
            (...result) => this.resolve(...result),
            (...why) => this.reject(...why)
        );
    }
}

export class Queue {
    constructor(size = 1) {
        this.size = size;
        this.queue = [];
        this._running = 0;
    }

    start() {
        if (this._running >= this.size) return;
        if (this.queue.length === 0) return;

        this._running += 1;
        const [cb, delegate] = this.queue.shift();
        delegate.mirror(cb());

        const restart = () => {
            this._running - 1;
            this.start();
        }
        delegate.promise().then(restart, restart);
    }

    push(cb) {
        const delegate = new PromiseDelegate();
        this.queue.push([cb, delegate]);
        this.start();
        return delegate.promise();
    }

    is_running() {
        return this._running > 0;
    }
}

export function from(thennable) {
    const delegate = new PromiseDelegate();
    delegate.mirror(thennable);
    return delegate;
}

export async function async_detect(array, func) {
    if (array.length == 0)
        throw new RangeError("Array is empty.");

    for (let value of array) {
        try {
            return await func(value)
        } catch (_) {
            continue;
        }
    }
}

export function async(func) {
    const next = function next(gen, type, value, resolve, reject) {
        let result = undefined;
        try {
            result = gen[type](value)
        } catch (e) {
            return reject(e);
        }
        if (result.done) {
            return resolve(result.value);
        }
        result.value.then(
            function(value){next(gen, "next", value, resolve, reject);},
            function(e){next(gen, "throw", e, resolve, reject);}
        );
    };
    
    return function f(...args) {
        const gen = func.apply(this, args);
        return new Promise((resolve, reject) => {
            next(gen, "next", undefined, resolve, reject);
        });
    }
}

export function sleep(time) {
    return new Promise((rs) => setTimeout(() => rs(), time));
}

export function throttled(func) {
    const running = {};
    return function f(...params) {
        if (!running[this]) running[this] = {next: null, running: null};
        const current = running[this];

        const delegate = new PromiseDelegate();
        current.running = delegate;

        const next = async () => {
            try {
                delegate.resolve(await func.apply(this, params));
            } catch (e) {
                delegate.reject(e);
            } finally {
                if (current.next !== null) current.next();
                else                       running[this] = null;
            }
        }

        if (current.next === null)
            next();
        else
            current.next = next;
        
        return delegate.promise();
    }
}

export function joined(func) {
    const running = {};
    return function f(...params) {
        if (!!running[this]) return running[this];
        const result = func.apply(this, params);

        if (typeof result.then === "function") {
            running[this] = result;
            result.then(
                () => delete running[this],
                () => delete running[this]
            )
        }
        return result;
    }
}

export function forget(func) {
    return function f(...params) {
        const promise = func.apply(this, params);
        promise.then(() => {}, (reason) => console.error(reason));
        return promise;
    }
}

export function wrap(func) {
    return function f(...args) {
        const deferred = $.Deferred();
        const es6promise = func.apply(this, args);
        es6promise.then(
            (rs) => deferred.resolve(rs),
            (rj) => deferred.reject(rj)
        );
        return deferred.promise();
    }
}

export function wrapped(...wrappers) {
    if (wrappers.length == 1) return wrappers[0]();
    let func = wrappers.pop();

    wrappers.reverse();

    for (let wrapper of wrappers)
        func = wrapper(func);

    return func();
}

export function run(self, func) {
    if (!func) {
        func = self;
        self = null;
    }
    async(func).call(self);
}
