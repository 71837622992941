<template>
    <input type="text"></input>
</template>

<script>
import $ from 'tm:jQuery';

export default {
    props: ['value'],
    mounted: function() {
        var el = this.$el;
        var $this = this;
        let spectrum = $(el).spectrum({
            preferredFormat: "hex",
            allowEmpty: true,
            showInput: true,
            showInitial: true,
            clickoutFiresChange: true
        });

        $(el).spectrum("set", this.value);

        spectrum
            .on('change', function() {
                $this.$emit('input', this.value);
            });
    },
    beforeDestroy: function() {
        $(this.$el).spectrum('hide').spectrum('destroy');
    },

    watch: {
        value: function() {
            $(this.$el).spectrum("set", this.value);
        }
    }
}
</script>
