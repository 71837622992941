//!!alias vue/filters/math

import Vue from 'tm:vue';
import { l } from 'tm:widgets/helpers/translations';

Vue.filter('multiply', function add(value, other) {
    return value*other;
});

Vue.filter('divide', function divide(value, through) {
    return value/through;
});

Vue.filter('add', function add(value, other) {
    return value+other;
});

Vue.filter('subtract', function add(value, other) {
    return value-other;
});

Vue.filter('decimal_places', function decimal_places(value, other) {
    return value.toFixed(other);
});

Vue.filter('currency', function currency(value, currency) {
    if (!currency) currency = "EUR";
    return l(value, {style: 'currency', currency: currency});
})
