
class _Spinner {
  private _show: () => void = () => {};
  private _hide: () => void = () => {};
  private _counter = 0;

  _configure(show: () => void, hide: () => void) {
    this._hide();

    this._show = show;
    this._hide = hide;

    if (this._counter > 0) this._show();
  }

  show() {
    if (this._counter++ == 0) {
      this._show();
    }
  }

  hide() {
    if (--this._counter == 0) {
      this._hide();
    }
  }

  forPromise<T>(p: Promise<T>): Promise<T> {
    this.show();
    p.then(() => { this.hide() }, () => { this.hide() });
    return p;
  }

  run<T>(cb: () => Promise<T>): Promise<T> {
    return this.forPromise(cb());
  }

  around<T>(promise: Promise<T>): Promise<T> {
      return this.forPromise(promise);
  }

  wrap<T>(cb: (...args: any[]) => Promise<T>): (...args: any[]) => Promise<T> {
      return (...args: any[]) => cb(...args);
  }

  hide_until<T>(promise: Promise<T>): Promise<T> {
      throw new Error("Unsupported operation Spinner#hide_until");
  }
}

const Spinner = new _Spinner();
export default Spinner;
