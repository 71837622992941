//!!alias widgets/helpers/etc

import _ from 'tm:underscore';
import $ from 'tm:jQuery';

export function include(name) {
    return WidgetAPI.modules.require(name);
}

export function consoleGroup(message, func) {
    if (!!console.groupCollapsed)
        console.groupCollapsed(message);
    else if (!!console.group)
        console.group(message);
    else
        console.info(message);

    try {
        return func();
    } catch (e) {
        throw e;
    } finally {
        if (!!console.groupEnd)
            console.groupEnd()
    }

}

export function new_counter(begin=0, delta=1) {
    let current = begin;

    const counter =  function counter() {
        const result = current;
        current += delta;
        return result;
    }
    counter.copy = function copy() {
        return new_counter(current, delta);
    }
    return counter;
}

export function once(func) {
    var _cache = undefined;
    return function() {
        if (_cache === undefined)
            _cache = func.apply(this, arguments);
        return _cache;
    }
};

export function ready() {
    return new Promise(function(resolve) {
        $(document).ready(resolve);
    })
};

export function deepcopy(object) {
    if (object instanceof Array)
        return _.map(object, function(v){return deepcopy(v);});
    return $.extend(true, {}, object);
};

export function decorated(decorators, func) {
    decorators.reverse();
    for (let decorator of decorators) {
        func = decorator(func);
    }
    return func;
};

export function run(decorators) {
    const cb = decorators.pop()
    return decorated(decorators, cb)();
}

export function execute(...decorators) {
    return run(decorators);
}

export function bound(parent, func) {
    return function _bound_function(...args) {
        return func.apply(parent, args);
    };
};

export function nextTick() {
    return new Promise((rs) => {
        if (!!window.setImmediate) window.setImmediate(rs);
        setTimeout(rs, 0);
    })
}

export function wait(time) {
    return new Promise(rs => setTimeout(rs, time));
}

export function noop(){}
