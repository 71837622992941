import { ShopConnector } from "./api";
import { Chart } from "./chart";
import { ContingentPriceData, DiscountPriceData, EventData, PriceData, UnseatedTicket } from "./connector";


export class Event {
    private _info: EventData;
    protected _connector: ShopConnector

    constructor(info: EventData, connector: ShopConnector) {
        this._info = info;
        this._connector = connector;
    }

    get id(): number {
        return this._info.id;
    }

    get name(): string {
        return this._info.name || "";
    }

    get badge(): string {
        return this._info.badge;
    }

    get entryDate(): Date {
        return new Date(this._info.entrytime);
    }

    get startDate(): Date {
        return new Date(this._info.starttime);
    }

    get tags(): string[] {
        return [...this._info.tags];
    }

    get endDate(): Date {
        return new Date(this._info.endtime);
    }

    get description(): string {
        return this._info.description; 
    }

    get showPrices(): boolean {
        return this._info.state.pricesShown;
    }

    get doSell(): boolean {
        return this._info.state.saleActive;
    }

    async getPriceData(): Promise<EventPriceInformation> {
        const _data = await this._connector.getEvent(this._info.id);
        return new EventPriceInformation(_data, this._connector);
    }

    getEvent(): Promise<Event> {
        return Promise.resolve(this);
    }

    get seated(): boolean {
        return this._info.seated;
    }

    async getSeatingChart(): Promise<Chart|null> {
        if (!this.seated) return null;

        const chart = await this._connector.getSeatingChart(this._info.id);
        return new Chart(this._connector, chart, this._info.id);
    }
}


export class EventPriceInformation {
    private _data: PriceData
    protected _connector: ShopConnector

    constructor(data: PriceData, connector: ShopConnector) {
        this._connector = connector;
        this._data = data;
    }

    getPriceData(): Promise<EventPriceInformation> {
        return Promise.resolve(this);
    }

    async getEvent(): Promise<Event> {
        const results = await this._connector.listEvents({id: this.id});
        if (results.length === 0) throw new Error("Can't find event with ID");
        return new Event(results[0], this._connector);
    }

    get id(): number {
        return this._data.id;
    }

    get name(): string {
        return this._data.evName;
    }

    get date(): Date {
        return new Date(this._data.evDate);
    }

    get categories(): Category[] {
        return this._data.categories.map(c => new Category(this.id, c, this._connector));
    }
}


export class Category {
    private _event_id: number;
    private _data: ContingentPriceData;
    private _connector: ShopConnector;

    constructor(eid: number, data: ContingentPriceData, connector: ShopConnector) {
        this._event_id = eid;
        this._data = data;
        this._connector = connector;
    }

    get id(): number {
        return this._data.id;
    }

    get name(): string {
        return this._data.ecName;
    }

    get price(): number {
        return this._data.price;
    }

    __ticket(): UnseatedTicket {
        return {
            event: this._event_id,
            category: this.id
        }
    }

    async contingent(): Promise<number> {
        return await this._connector.freeTicketsOfEvent(this._event_id, this.id);
    }

    get ticketCount(): number {
        return this._data.contingent.all;
    }

    get discounts(): Discount[] {
        return this._data.discounts.map(d => new Discount(this._event_id, this.id, d));

    };
}


export class Discount {
    private _event_id: number;
    private _category_id: number;
    private _data: DiscountPriceData;

    constructor(eid: number, cid: number, data: DiscountPriceData) {
        this._event_id = eid;
        this._category_id = cid;
        this._data = data;
    }

    get id(): number {
        return this._data.id;
    }

    get name(): string {
        return this._data.ecdName;
    }

    get price(): number {
        return this._data.price;
    }

    __ticket(): UnseatedTicket {
        return {
            event: this._event_id,
            category: this._category_id,
            discount: this.id
        }
    }
}
