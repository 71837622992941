<template>
    <div class="in"><slot></slot></div>
</template>

<script>
//!!alias widgets/bootstrap/collapse
import $ from 'tm:jQuery';

/**
 * Wraps a modal.
 *
 * This allows to more accurately operate on modals as all wrapped functions
 * return promises that can be awaited now.
 */
export default {
    props: ['value'],

    watch: {
        value: function () {
            if (this.value) {
                $(this.$el).collapse("show");
            } else {
                $(this.$el).collapse("hide");
            }
        }
    }
}
</script>
