import { exposeFunction } from "@/utils/pages";
import Spinner from "@frontend/spinner";

Spinner._configure(
  () => {
    const sc = document.createElement("div");
    sc.classList.add("spinner-container");
    const sp = document.createElement("div");
    sp.classList.add("spinner");

    sc.appendChild(sp);
    document.body.appendChild(sc);
  },

  () => {
    document.querySelector(".spinner-container")?.remove();
  }
)

exposeFunction("Spinner", (toggle) => {
  if (toggle === "hide") {
    Spinner.hide();
  } else {
    Spinner.show();
  }
});
