import { SvelteComponent } from "svelte";

export function handleComponentError(instance: SvelteComponent) {
    const update = (instance as any).$$.update;
    (instance as any).$$.update = function (...args: any[]) {
        try {
            (update||(() => {})).call(this, ...args)
        } catch (e) {
            window.reportError(e);
        }
    };
}
