import { promiseDelegate } from "@/utils/promises";

const KILOBYTE = 1024;
const MAX_CHUNK_SIZE = 100 * KILOBYTE;
const tick = () => new Promise(rs => requestAnimationFrame(() => rs));

async function __stringifyNumber(data: number, write: (cb: string) => Promise<void>): Promise<void> {
    await write("" + data);
}

async function __stringifyBoolean(data: boolean, write: (cb: string) => Promise<void>): Promise<void> {
    if (data)
        await write("true");
    else
        await write("false");
}

async function __stringifyNull(_: null, write: (cb: string) => Promise<void>): Promise<void> {
    await write("null");
}

async function __stringifyText(data: string, write: (cb: string) => Promise<void>): Promise<void> {
    await write('"');

    let idx = 0;
    while (idx < data.length) {
        const chunk = data.substring(idx, idx + MAX_CHUNK_SIZE);
        idx += MAX_CHUNK_SIZE;

        const escaped = JSON.stringify(chunk)
        await write(escaped.substring(1, escaped.length-1));
    }

    await write('"');
}


async function __stringifyArray(data: any[], write: (cb: string) => Promise<void>): Promise<void> {
    await write("[");
    let first = true;
    for (let item of data) {
        if (item === undefined) continue;

        if (!first) await write(",");
        first = false;
        await __stringify(item, write);
    }
    await write("]");
}

async function __stringifyMap(data: {[key: string]: any}, write: (cb: string) => Promise<void>): Promise<void> {
    await write("{");
    let first = true;
    for (let key of Object.keys(data)) {
        const value = data[key];
        if (value === undefined) continue;

        if (!first) await write(",");
        first = false;
        
        await __stringifyText(key, write);
        await write(":");
        await __stringify(value, write);
    }
    
    await write("}");
}

async function __stringify(data: any, write: (cb: string) => Promise<void>): Promise<void> {
    if (data === undefined) return;
    if (data === true || data === false) return await __stringifyBoolean(data, write);
    if (data === null) return await __stringifyNull(null, write);
    if (typeof data === "number") return await __stringifyNumber(data, write);
    if (typeof data === "string") return await __stringifyText(data, write);
    if (data instanceof Array) return await __stringifyArray(data, write);
    if (typeof data === "object") return await __stringifyMap(data, write);
    throw new Error(`Cannot serialize asynchronnously: ${data}`);
}


class StringLengthQueuingStrategy {
    private _highWaterMark: number;
    constructor(highWaterMark: number) {
        this._highWaterMark = highWaterMark;
    }

    get highWaterMark() : number {
        return this._highWaterMark;
    }

    size(chunk: string) {
        return chunk.length;
    }
}


/* Creates a readable stream rendering a json object. It supports back-pressure. */
export function stream(data: any): ReadableStream<string> {
    let signal: Promise<void>;
    let r: () => void = () => {};
    let j: (_: any) => void = () => {};

    let cancelled = false;

    return new ReadableStream({
        start(controller) {
            __stringify(data, async (chunk: string) => {
                if (cancelled) throw new Error("Cancelled");

                controller.enqueue(chunk);

                // After enqueuing the chunk, apply backpressure.
                if ((controller.desiredSize||1) <= 0) {
                    [signal, r, j] = promiseDelegate();
                    await signal;
                    r = () => {};
                    j = () => {};
                }
            }).then(() => controller.close(), (e) => controller.error(e));
        },
        pull() {
            r();
        },
        cancel() {
            cancelled = true;
            j(new Error("Cancelled"));
        }

    }, new StringLengthQueuingStrategy(MAX_CHUNK_SIZE));
}

export function byteStream(data: any): ReadableStream {
    return stream(data).pipeThrough(new TextEncoderStream());
}

async function _stringify_array(data: any): Promise<string[]> {
    const result: string[] = [];
    let tickCounter = 0;
    let lastTick = 0;
    await __stringify(data, async (text) => { 
        result.push(text);
        tickCounter += text.length;
        if ((tickCounter - lastTick) > MAX_CHUNK_SIZE) {
            lastTick = tickCounter;
            await tick();
        }
    });
    return result;
}


export async function stringify(data: any): Promise<string> {
    let response;
    if (window["ReadableStream"] && window["TextEncoderStream"]) {
        const dataStream = byteStream(data);
        response = new Response(dataStream);
    } else {
        const blob = new Blob(await _stringify_array(data), {type: "application/json"});
        response = new Response(blob);
    }
    return await response.text();
}


export async function byteify(data: any): Promise<ArrayBuffer> {
    let response;
    if (window["ReadableStream"] && window["TextEncoderStream"]) {
        const dataStream = byteStream(data);
        response = new Response(dataStream);
    } else {
        const blob = new Blob(await _stringify_array(data), {type: "application/json"});
        response = new Response(blob);
    }
    return await response.arrayBuffer();
}
