<template>
  <input type="text" @focus="enter" @blur="exit" v-model="displayValue"></input>
</template>

<script>
//!!alias widgets/inputs/currency

import Translations from 'tm:widgets/helpers/translations';
export default {
  name: 'currency',
  props: ['value', 'currency'],

  data: function() {
    return {
      blurred: true
    }
  },

  methods: {
    enter: function() {
      this.blurred = false;
    },
    exit: function() {
      this.blurred = true;
    },

    toActualNumber: function toReadableNumber(n) {
      var result = Translations.parseNumber(n);
      if (isNaN(result)) result = 0;
      return result;
    }
  },

  computed: {
    displayValue: {
      get: function() {
        if (this.blurred) {
          return Translations.l(this.value, {style: "currency", currency: this.currency || 'EUR'})
        } else {
          return Translations.l(Math.round(this.value.toString()*100)/100);
        }
      },

      set: function(value) {
        var num = this.toActualNumber(value);
        this.$emit("input", num);
      }
    },
  }
};
</script>
