//!!alias widgets/helpers/ajax

import InBackend from 'tm:backend';
import WebshopInfo from 'tm:frontend';
import Organizer from 'tm:organizer_info';

import $ from 'tm:jQuery';
import _ from 'tm:underscore';

import TmngVersion from 'tm:tmng_version_hash';
import { isArray, of } from 'tm:widgets/helpers/array';
import { PromiseDelegate } from 'tm:widgets/helpers/promise';
import { consoleGroup, include } from 'tm:widgets/helpers/etc';
import Translations from 'tm:widgets/helpers/translations';

const open_flashes = {};

var ajax_raw = function ajax_raw(url, config) {
    // Ensure we are operating on a copy.
    config = _.extend(config);
    config.headers = config.headers || {};

    if (!!config.contentType && config.contentType.indexOf('application/json') === 0) {
        config.data = JSON.stringify(config.data);
        config.processData = false;
    }

    var acceptHeader = null;
    if (!!config.dataType && !(config.headers["Accept"]||config.headers["accept"])) {
        if (config.dataType === "script" || config.dataType === "jsonp") {
            acceptHeader = "application/javascript";
        } else if (config.dataType === "html") {
            acceptHeader = "text/html";
        } else if (config.dataType === "json") {
            acceptHeader = "application/json";
        } else if (config.dataType === "text") {
            acceptHeader = "text/plain";
        } else if (config.dataType === "xml") {
            acceptHeader = "application/xml";
        }
    } else if (!config.dataType && !!config.contentType && config.contentType.indexOf('application/json') === 0) {
        acceptHeader = "application/json,application/javascript;q=0.6,text/plain;q=0.5,*/*;q=0.1";
    }

    if (acceptHeader !== null) {
        if (!!config.headers["accept"])
            config.headers["accept"] = acceptHeader;
        else
            config.headers["Accept"] = acceptHeader;
    }

    config.method = (config.method || 'get').toUpperCase();

    var _base_promise = $.ajax(url, config);
    var pdelegate = new PromiseDelegate();
    _base_promise.then(function(result) {
        pdelegate.resolve.apply(pdelegate, arguments);
    }, function(err) {
        if (err.status === 500) {
            consoleGroup("Server-Side Exception during AJAX-Request", () => {
                console.info("Response: " + ("" + err.status) + " for " + config.method + " " + url);
                console.info("Request-ID: " + err.getResponseHeader("x-request-id") || "No Request-ID given.");

                // We got the Exception attached by the server. This means, we are in development mode.
                if (!!err.responseJSON && !!err.responseJSON.exception) {
                    console.error("" + err.responseJSON.exception.type + ": " + err.responseJSON.exception.message + "\n" + err.responseJSON.exception.backtrace.join("\n"));
                }
            });
        }

        var _message;
        var _code = null;

        try {
            _message = err.responseJSON.error
        } catch (e) {/* IGNORED */}
        try {
            _code = err.responseJSON.code;
        } catch (e) {/* IGNORED */}

        if (!!config.skip_message) {
            var _skip = false;

            if (typeof config.skip_message === "function") {
                _skip = config.skip_message(err.status, _code);
            } else if (config.skip_message instanceof Array) {
                _skip = config.skip_message.index(error.status) < 0;
            } else if (typeof config.skip_message === "object") {
                if (_code !== null && !!config.skip_message[_code])
                    _skip = true;
                else
                    _skip = config.skip_message[error.status];
            } else if (typeof config.skip_message === "boolean") {
                _skip = true;
            }

            if (_skip) {
                err.isHandledByHelper = false;
                pdelegate.reject.apply(pdelegate, [err]);
                return;
            }
        }

        if (!_message)  _message = Translations._("error.http." + err.status);

        if (_message !== null) {
            include("widgets/v1/flash").then((Flash) => {
                if (_code !== null) {
                    if (!!open_flashes[_code])
                        open_flashes[_code].kill();
                    open_flashes[_code] = Flash.error(_message)
                } else {
                    Flash.error(_message);
                }
            })

            err.isHandledByHelper = true;
        } else {
            err.isHandledByHelper = false;
        }

        pdelegate.reject.apply(pdelegate, [err]);
    });
    return pdelegate.promise();
};

export async function ajax(url, config) {
    if (typeof url === "object") {
        const resolved = await resolve_url(url.data)
        delete url.data.controller;
        delete url.data.action;

        config = url;
        url = resolved;
    }
    return ajax_raw(url, config);
};

export function cache_access(func, maxtime) {
    var _cache_promise = null;
    var _cache_last_access = 0;

    var cb = function() {
        if (_cache_promise !== null)
            if ((maxtime||0) > 0 && _cache_last_access + maxtime > new Date().getTime())
                return _cache_promise;

        _cache_last_access = new Date().getTime();
        return (_cache_promise = func());
    };

    cb.invalidate = function invalide() {
        _cache_promise = null;
        _cache_last_access = 0;
    };

    return cb;
};

export function resolve_dynamic_url(url, data) {
    var actual_url = url;
    var data = _.extend(data);

    actual_url = actual_url.replace(':locale', I18n.config.tag);
    actual_url = actual_url.replace(':organizer_id', Organizer.organizer_id);
    if (!InBackend) actual_url = actual_url.replace(':webshop', WebshopInfo.webshop);

    if (typeof data === "object") {
        _.each(data, function (value, name) {
            if (!value || !name) return;
            if (actual_url.indexOf(':' + name) < 0) return;

            if (typeof value === Array)
                value = value.join(",");

            actual_url = actual_url.replace(':' + name, value);
            data[name] = undefined;
        });
    };

    return {url: actual_url, data: data};
};

export function to_query(url, data) {
    var deparam = function parse_q(url) {
        if (url.indexOf("?") === -1)
            return {url: url, params: []};

        var params = [];
        var qs = url.substring(url.indexOf('?')+1).split("&");
        var url = url.substring(0, url.indexOf('?'));

        if (qs.length == 1 && qs[0].length == 0)
            qs = [];

        for (var i = 0; i<qs.length; i++) {
            params.push(qs[i]);
        }

        return {
            url: url,
            params: params
        }
    };
    var dispatch = function dispatch(parts, prefix, value) {
        if (["number", "string"].indexOf(typeof value) >= 0) {
            parts.push(prefix + "=" + encodeURIComponent("" + value));
        } else if (isArray(value)) {
            o2a(parts, prefix, value);
        } else {
            o2o(parts, prefix, value);
        }
    };
    var o2a = function o2a(parts, prefix, value) {
        prefix = prefix + "[]";
        for (var i=0; i<value.length; i++) {
            dispatch(parts, prefix, value[i]);
        }
    };
    var o2o = function o2o(parts, prefix, value) {
        for (let key in value) {
            if (prefix.length > 0)
                key = "["+key+"]";

            dispatch(parts, prefix+key, value[key]);
        }
    };

    var dp = deparam(url);

    var parts = dp.params;
    url = dp.url;
    dispatch(parts, "", data);
    if (parts.length == 0)
        return url;
    return url + "?" + parts.join("&");
};

export function asset_url(url) {
    var arg_length = arguments.length;
    var result = ajax(
        ('/api/path/assets' + to_query('', {pathes: of(arguments)})),
        {method: 'GET'}
    );
    return new Promise(function(resolve, reject) {
        result.then(function(args) {
            if (arg_length == 1) {
                if (!args[0]) {
                    console.warn("Failed to receive static asset path.");
                    resolve('/assets/' + args[0]);
                    return;
                }
                resolve(args[0]);
            } else {
                resolve(args);
            }
        }).catch(function(err) {
            console.warn("Failed to receive static asset path.", err);
            resolve('/assets/' + url);
        });
    });
};

function __tmcache(key) {
    if (!window.sessionStorage)
        return null;
    
    const cacheKey = `tm-cache.ajax.${key}`;
    const result = window.sessionStorage.getItem(cacheKey);
    if (!result) return null;
    const parsedResult = JSON.parse(result);
    if (parsedResult.version !== TmngVersion) {
        window.sessionStorage.removeItem(cacheKey);
        return null;
    }
    return parsedResult.value;
}

__tmcache.push = function __tmcache__push(key, value) {
    if (!window.sessionStorage) return;
    window.sessionStorage.setItem(`tm-cache.ajax.${key}`, JSON.stringify({value: value, version: TmngVersion}));
}


let _batchRequests = null;
async function performRequest(data) {
    if (_batchRequests === null) {
        _batchRequests = [];
        setTimeout(() => {
            (async () => {
                const currentRequests = _batchRequests;
                _batchRequests = null;

                const queries = currentRequests.map(({data}) => data);
                let results;
                try {
                    results = await ajax(
                        '/api/path/resolve', 
                        {
                            method: 'POST',
                            dataType: 'json',
                            contentType: 'application/json',
                            data: {__batch_resolve: queries}
                        }
                    );
                } catch (e) {
                    currentRequests.forEach(({rj}) => rj(e));
                    throw e;
                }

                results.forEach((url, i) => currentRequests[i].rs(url));
            })().catch(window.reportError);
        }, 1);
    }

    return await new Promise((rs, rj) => {
        _batchRequests.push({data, rs, rj});
    })
}


export function resolve_url(data) {
    data = _.extend(data);
    if (Organizer.organizer_id !== null && !data.organizer_id)
        data.organizer_id = Organizer.organizer_id;
    data.locale = I18n.config.tag;
    if (!InBackend) data.__webshop = WebshopInfo.webshop;
    var query = to_query('/api/path/resolve', data)

    var cached =__tmcache("resolve_url?" + query)
    if (!!cached) return Promise.resolve(cached);

    var promise = performRequest(data).then(function(url) {
        var keys = Object.keys(data);
        for (var i = 0; i<keys.length; i++) {
            var obj = data[keys[i]];
            if (typeof obj !== "string") continue;
            if (obj.indexOf(":") != 0) continue;
            obj = obj.replace(":", "%3A");
            url = url.replace(obj, data[keys[i]]);
        }
        return url;
    }).then(function(url) {
        var index = url.lastIndexOf("?");
        if (index != -1)
            return url.substring(0, index);
        else
            return url;
    });
    promise.then(function(url) {
        __tmcache.push("resolve_url?" + query, url);
    })
    return promise;
};

var _asset_cache = {};

export function static_asset(url, config) {
    if (!!_asset_cache[url]) return _asset_cache[url];
    config = config || {};

    var delegate = new PromiseDelegate();
    _asset_cache[url] = delegate.promise();

    delegate.mirror(asset_url(url).then(function received(url) {
        return ajax(url, _.extend(config, {method: 'GET'}));
    }));
    return delegate.promise();
};

export async function build_location(url, data) {
    if (typeof url === "object") {
        url = await resolve_url(url);
    }

    ({url, data} = resolve_dynamic_url(url, data));

    url = to_query(url, data);
    return url;
}

export async function request(method, url, data, options) {
    if (!options) options = {};
    if (options.skip_message === undefined)
        options.skip_message = function(e) {return e !== 403};
    options["method"] = method;

    if (typeof url === "object") {
        url = await resolve_url(url);
    }

    ({url, data} = resolve_dynamic_url(url, data));

    if (method.toLowerCase() === "get")
        url = to_query(url, data);
    else
        options["data"] = data;

    return await ajax(url, options);
};
request.get = function request$get(url, data, options) {
    if (!options) options = {};
    if (!options.dataType) {
        if (!options.headers) options.headers = {};
        if (!options.headers["accept"] && !options.headers["Accept"]) {
            options.headers["Accept"] = "application/json,application/javascript;q=0.5,text/plain;q=0.4;text/html;q=0.2;*/*;q=0.1";
        }
    }
    return request('get', url, data, options);
};
request.post = function request$post(url, data, options) {
    return request('post', url, data, options);
};
