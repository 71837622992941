<template>
    <textarea v-model="value"></textarea>
</template>
<script>
//!!alias widgets/inputs/richeditor

import "tm:tinymce";
import { new_counter } from 'tm:widgets/helpers/etc';
import { MultiwayBinding } from 'tm:widgets/helpers/two-way-bindings';

const counter = new_counter();

export default {
    data() {
        return {
            state: "IDLE",
            editor: null,
            binding: new MultiwayBinding(),
            val_binding: null
        }
    },

    props: ['value'],
    mounted: function() {
        if (this.val_binding === null) {
            this.val_binding = this.binding.attach((value) => {
                if (this.editor.getContent() === value) return;
                this.editor.setContent(value);
            });
        }

        this.$el.classList.add(`tinymce--instance-${counter()}`);
        window.tinymce.init({
            target: this.$el,
            setup: (editor) => {
                const binding = this.binding.attach((value) => {
                    this.$emit('input', value);
                });
                this.editor = editor;
                editor.on('input change undo redo execCommand KeyUp NodeChange', () => {
                    binding.set(editor.getContent());
                });
            },
            menubar: false,
            plugins: ['lists textcolor link'],
            toolbar: 'undo redo | fontselect fontsizeselect | bold italic underline forecolor | bullist numlist | alignleft aligncenter alignright alignjustify | link',
            fontsize_formats: '8pt 10pt 12pt 14pt 16pt 24pt 36pt 72pt',
            font_formats: [
                'Arial=arial,sans-serif',
                'Helvetica=helvetica,sans-serif',
                'Times New Roman=times new roman,times,serif',
                'Courier New=courier new,courier,monospace',
            ].join(';')
        });
    },
    beforeDestroy() {
        if (this.editor) {
            this.editor.remove();
            this.editor.destroy();
        }
    },
    watch: {
        value() {
            this.val_binding.set(this.value);
        }
    }
}
</script>
