import { ShopConnector } from "./api";
import { BundleDescription, Permit, SnapshotItem } from "./connector";
import { CouponApplicationFailed } from "./errors";
import { Translator } from "./translations";
import type { BufferManager, BundlePermit, BundleSnapshot } from "./utils/wasm";


export class Bundle {
    private _connector: ShopConnector;
    private _translator: Translator<any>;
    private _discount: BundleDescription;

    constructor(connector: ShopConnector, translator: Translator<any>, discount: BundleDescription) {
        this._connector = connector;
        this._translator = translator;
        this._discount = discount;
    }

    get id() {
        return this._discount.id;
    }

    get name() {
        return this._discount.name;
    }

    /**
     * Applies the discount to the cart.
     */
    async check(): Promise<{success: boolean, reason: {code: string, text: string}}> {
        const {success, reason} = await this._connector.checkBundle(this._discount.id);

        return {
            success,
            reason: {
                code: reason,
                text: this._translator(`webshop.errors.coupons.${reason}`)
            }
        }
    }

    /**
     * Applies the discount to the cart.
     */
    async apply(): Promise<void> {
        const {success, reason} = await this._connector.applyBundle(this._discount.id);
        if (!success) {
            throw new CouponApplicationFailed(reason, this._translator(`webshop.errors.coupons.${reason}`));
        }
    }

    async loadChecker(): Promise<BundleVerifier|null> {
        const checkerConfig = await this._connector.loadBundleChecker(this._discount.id);
        if (checkerConfig === null) return null;
        const { wasm_url, settings } = checkerConfig;
        return new BundleVerifier(wasm_url, this._discount.name, settings);
    }
}


type Result<T> = {type: "error", reason: string} | {type: "success", value: T}


export class BundleVerifier {
    private _url: string;
    private _name: string;
    private _settings: any;

    private _instance: WebAssembly.Instance|null = null;
    private _buffers: BufferManager|null = null;

    private static _requestMap: Map<string, Promise<WebAssembly.Module>> = new Map();

    constructor(url: string, name: string, settings: any) {
        this._settings = settings;
        this._name = name;
        this._url = url
    }

    serialize(): string {
        return JSON.stringify({url: this._url, name: this._name, settings: this._settings})
    }

    static deserialize(value: string): BundleVerifier {
        const data = JSON.parse(value);
        return new BundleVerifier(
            data.url,
            data.name,
            data.settings
        );
    }

    // This function fetches the WASM module and caches it.
    private static async _fetchWasm(url: string): Promise<WebAssembly.Module> {
        if (!BundleVerifier._requestMap.has(url)) {
            BundleVerifier._requestMap.set(
                url,
                WebAssembly.compileStreaming(
                    fetch(url, {redirect: "follow"})
                )
            );
        }

        return await BundleVerifier._requestMap.get(url)!;
    }

    private async _ensureWasmLoaded(): Promise<void> {
        if (this._instance !== null) return;

        const { buildEnvironmentModule, BufferManager } = await import("./utils/wasm");

        const [env, envSettings] = buildEnvironmentModule(this._name);
        const instance = await WebAssembly.instantiate(await BundleVerifier._fetchWasm(this._url), {env} as any)
        envSettings.setInstance(instance);

        this._buffers  = new BufferManager(instance);
        this._instance = instance;
    }

    private _convertItem(item: SnapshotItem): BundleSnapshot|null {
        if (item.type === "permit") {
            const permit = item as Permit;
            if (permit.bundle_run !== null) return null;
            return {
                type: (permit.seat === null) ? "Permit" : "SeatedPermit",
                id: Number(item.id),
                event_id: Number(permit.event_id),
                event_category_id: Number(permit.category_id),
                event_category_discount_id: 
                    (permit.base_discount_id === null || permit.base_discount_id === undefined)
                    ? null
                    : Number(permit.base_discount_id),
                seat_id:
                    (permit.seat === null || permit.seat === undefined)
                    ? null
                    : Number(permit.seat.id),
                event_tags: permit.event_tags
            } as BundlePermit
        } else {
            return null;
        }
    }

    private _invoke<T>(name: string, ...params: any[]): T {
        const func = this._instance!.exports[name];
        if (!func)
            throw new Error(`Unknown export ${name}.`);
        const args = params.map(param => this._buffers.serializeObject(param));
        const rawResult = (func as CallableFunction)(...args);
        const result = this._buffers.deserializeObject<Result<T>>(rawResult);

        if (result.type === "error") {
            throw new Error(result.reason as string);
        }
        return result.value;
    }

    async verify(snapshot: SnapshotItem[]): Promise<boolean> {
        await this._ensureWasmLoaded();

        const items = snapshot.map(item => this._convertItem(item)).filter(f => f !== null);

        const match_item_ids = this._invoke<number[]>("match", this._settings, items);
        if (match_item_ids === null) return false;

        const matched_items = match_item_ids.map(i => items.find(it => it.id === i));
        if (matched_items.length === 0) return false;
        return this._invoke("verify", this._settings, matched_items);
    }

    async match(snapshot: SnapshotItem[]): Promise<BundleSnapshot[]> {
        await this._ensureWasmLoaded();

        const items = snapshot.map(item => this._convertItem(item)).filter(f => f !== null);
        if (items === null) throw new Error("Can't match the bundle");

        const result: Result<number[]> = this._invoke("match", this._settings, items);
        if (result.type === "error") {
            throw new Error(result.reason as string);
        }
        return result.value.map(v => items.find(s => s.id === v));
    }
}

