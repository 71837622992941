//!!alias widgets/vault/remote

import { ajax } from 'tm:widgets/helpers/ajax';

class RemoteVaultNamespace {
    constructor(vault) {
        this.vault = vault;
    }

    async find(key) {
        const result = await ajax('/api/vault/' + this.vault.name + '/' + key + '', {method: 'GET'});
        if (result === null)
            return null;

        return result;
    }

    async set(key, value) {
        return await ajax('/api/vault/' + this.vault.name + '/' + key + '', {method: 'POST', data: {value: value}, contentType: 'application/json'});
    }
}

export default class RemoteVault {
    constructor(name) {
        this.name = name;
    }

    get_namespace(name) {
        if (name !== this.name) {
            return null;
        }
        return new RemoteVaultNamespace(this);
    }
}
