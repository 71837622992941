export default class Cache {
    constructor() {
        this.cache = new Map();
    }

    async hit(key, func) {
        if (!this.cache.has(key)) {
            this.cache.set(key, await func());
        }
        return this.cache.get(key);
    }
}