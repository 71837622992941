import { defaultPromise } from "./utils/signals";
import { type Signal } from "./connector";


export const LanguageCode = Symbol("@languageCode");
export const BrowserLanguage = Symbol("@browserLanguage");

/**
 * A translator implements translations for the components.
 *
 * Since it doesn't know how advanced the translator is,
 * it takes multiple parameters,
 * to support different translation systems.
 *
 * The names array can be used to convert the values-object into an array for index-based interpolation.
 *
 * If both values and names are omitted, assume no dynamic values have been passed.
 * 
 * The following examples should give some intuition:
 *   Translator(key, {a: "1", b: "2"}, ["b", "a"])
 *   -> Index based should be ["2", "1"]
 *   -> Name based should be {"a": "1", "b": "2"}
 *
 *   Translator(key, {a: "1"}, ["a"])
 *   -> Index based should be ["1"]
 *   -> Name based should be {"a": "1"}
 *
 * A translator is usually given as a signal.
 * This allows updates to the translation during runtime.
 * Just send in a new instance,
 * and all frontend items will automatically update the displayed translation.
 *
 * The translator might receive a symbol as a key. These keys are:
 * - BrowserLanguage, if passed, it should return a locale suitable for use in toLocaleString
 *
 * If values and names is null, do not perform any kind of variable subsitution,
 * even if the text contains variables.
 */
export type Translator<T extends object> = 
    (
        ((key: string, values: T|null, names: (keyof T)[]) => string)
    &   ((key: string, values: null, names: null) => string)
    &   ((key: string|symbol)=> string)
    );

/**
 * Translation-Hints are small wrappers around objects.
 *
 * They clarify how to format the value.
 *
 * Each TranslationHint object has a toLocaleString() function which automatically does the right thing,
 * depending on the language of the user.
 * Additionally, it also defines toLocaleString(string) which can be used with a language-tag.
 *
 * To get the raw value, use the .value() function,
 * which uncovers the raw value wrapped by the object.
 */
export class TranslationHint<T> {

    get value(): T {
        throw new Error("Not implemented.");
    }

    toString(): string {
        throw new Error("Not implemented.");
    }

    toLocaleString(locale?: string): string {
        throw new Error("Not implemented.");
    }

}


/**
 * Count is just a thin wrapper around a number.
 * It is used to denote a value that is pluralizable.
 */
export class Count extends TranslationHint<number> {
    private _amount: number;

    constructor(amount: number) {
        super();
        this._amount = amount;
    }

    get value(): number {
        return this._amount;
    }

    valueOf(): number {
        return this._amount;
    }

    toString(): string {
        return new Intl.NumberFormat("en-US", {}).format(this._amount);
    }

    toLocaleString(locale = navigator.language): string {
        return new Intl.NumberFormat(locale, {}).format(this._amount);
    }
}


/**
 * Price is just a thin wrapper around a number.
 * It is used to denote a price for the translation-system.
 */
export class Price extends TranslationHint<number> {
    private _amount: number;

    constructor(amount: number) {
        super();
        this._amount = amount;
    }

    get currency(): string {
        return "EUR";
    }

    get amount(): number {
        return this._amount;
    }

    get value(): number {
        return this._amount;
    }

    valueOf(): number {
        return this._amount;
    }

    toString(): string {
        return new Intl.NumberFormat("en-US", {style: "currency", currency: this.currency}).format(this._amount);
    }

    toLocaleString(locale = navigator.language): string {
        return new Intl.NumberFormat(locale, {style: "currency", currency: this.currency}).format(this._amount);
    }
}


export const DefaultTranslator = defaultPromise(
    () => "",                                                    // Translations not loaded yet.
    async () => await (await import("./i18n")).default()         // Translations have been loaded.
);


export const GermanTranslator = defaultPromise(
    () => "",
    async () => await (await import("./i18n")).default("de")     // Translations have been loaded.
) as Signal<Translator<any>>;


export const EnglishTranslator = defaultPromise(
    () => "",
    async () => await (await import("./i18n")).default("en")     // Translations have been loaded.
) as Signal<Translator<any>>;

