import "@/_error";
import "./errors";

import "@/platform";

import "./legacy";

/**
 * This should include code common to all
 * frontend components.
 */
import { Shop } from "@ticketmachine/sdk";

import { exportModuleRaw, entrypointModule, exposeFunction, exportModule } from "../../utils/pages"; 
import "./index.less";
exportModule("underscore", () => import("underscore"));
exportModuleRaw("widgets/helpers/translations", () => import("../../utils/translations"));
entrypointModule("cms/preview", () => import("./cms/preview"));
entrypointModule("cms/blocks", () => import("./cms/blocks"));
exportModuleRaw("widgets/spinner", () => import("../lib/spinner"));


import "../../utils/translations";
import '../../cookies/index';
import "./forms/conditional_dropdown";

(async () => {
    await import("../lib/sdk");
})().catch(window.reportError);

exposeFunction("reloadCart", async function reloadCart() {
    Shop.current.cart.reload();
});
