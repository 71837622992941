//!!alias widgets/bootstrap/tooltips

import Vue from 'tm:vue';
import $ from 'tm:jQuery';

/**
 * Implement tooltips for VueJS Components.
 */
function calculate_binding(binding) {
    var result = {
        container: "body"
    };

    result.title = binding.value;

    if (binding.modifiers.left)   result.placement = "left";
    if (binding.modifiers.right)  result.placement = "right";
    if (binding.modifiers.bottom) result.placement = "bottom";
    if (binding.modifiers.top)    result.placement = "top";

    return result;
};

Vue.directive('tooltip', {
    inserted: function(el, binding) {
        $(el).tooltip(calculate_binding(binding));
    },
    componentUpdated: function(el, binding) {
        $(el).tooltip("destroy");
        $(el).tooltip(calculate_binding(binding))
    },
    unbind: function(el) {
        $(el).tooltip("destroy");
    }
});
