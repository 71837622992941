//!!alias widgets/helpers/two-way-bindings

class SingleBinding {
    constructor(binding, cb) {
        this.cb = cb;
        this.current_value = undefined;
        this.binding = binding;
    }

    _set(value) {
        if (this.current_value == value) return;
        this.cb(value);
        this.current_value = value;
    }

    set(value) {
        this.binding.set(value);
    }
}

export class MultiwayBinding {
    constructor() {
        this.bindings = [];
    }

    attach(cb) {
        var binding = new SingleBinding(this, cb);
        this.bindings.push(binding);
        return binding;
    }

    set(value) {
        for (let binding of this.bindings) {
            binding._set(value);
        }
    }
}

export class DelayedBinding {
    constructor() {
        this.latest_timeout = null;
        this.current_value = null;
        this.delay = 100;
        this.callback = null;
    }

    update(value) {
        this.current_value = value;
        if (this.latest_timeout !== null)
            clearTimeout(this.latest_interval)

        this.latest_timeout = setTimeout(() => {
            if (!!this.callback)
                this.callback(this.current_value);
        }, this.delay);
    }

    set(value) {
        this.update(value);
    }
}
