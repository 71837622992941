//!!alias vue/filters/date

import Vue from 'tm:vue';
import { code } from 'tm:widgets/helpers/translations';


Vue.filter('date', function date(value) {
    if (!value) return new Date();
    if (typeof value === "number") value = value*1000;
    return new Date(value);
});

Vue.filter('datetime_locale', function date_locale(value, locale) {
    if (!locale) locale = code;
    return value.toLocaleString(locale);
});
Vue.filter('date_locale', function date_locale(value, locale) {
    if (!locale) locale = code;
    return value.toLocaleDateString(locale, {month:"2-digit", day: "2-digit", year: "numeric"})
});
