export class CouponApplicationFailed extends Error {
    private __reasonCode: string;
    constructor(reasonCode: string, reasonText: string) {
        super(reasonText);
        this.__reasonCode = reasonCode;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, CouponApplicationFailed.prototype);
    }

    get reasonCode() {
        return this.__reasonCode;
    }

}

