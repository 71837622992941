import { readable, type Readable } from "svelte/store";


export default class StateRegistry {
    private state: Map<string, any> = new Map();
    private listeners: Map<string, Map<number, (value: any) => void>> = new Map();
    private _idCounter: number = 0;

    private hasListeners(name: string): boolean {
        return this.listeners.has(name) && this.listeners.get(name)!.size > 0;
    }

    private callListeners(name: string, value: any): void {
        if (!this.hasListeners(name)) return;

        for (let listener of this.listeners.get(name)!.values()) {
            listener(value);
        }
    }

    public set(name: string, value: any) {
        this.state.set(name, value);
        this.callListeners(name, value);
    }

    public subscribe<T>(name: string, listener: (value: T)=>void): () => void {
        const myId = (this._idCounter++);

        if (!this.listeners.has(name))
            this.listeners.set(name, new Map());

        this.listeners.get(name)!.set(myId, listener);

        return () => {
            this.listeners.get(name)!.delete(myId);
        }
    }

    public warmWith<T>(name: string, loader: PromiseLike<T>): PromiseLike<T> {
        loader.then((result) => {
            if (!this.state.has(name)) {
                this.set(name, result);
            }
        });
        return loader;
    }

    public listen<T>(name: string, def: T|undefined=undefined): Readable<T> {
        const value = this.state.has(name) ? (this.state.get(name)! as T) : def;
        return readable(value, (set) => {
            const stopper = this.subscribe<T>(name, (newValue) => {
                set(newValue);
            });

            return () => {
                stopper();
            };
        });
    }
}
