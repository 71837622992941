<template>
    <input type="text"></input>
</template>

<script>
//!!alias widgets/inputs/time

import $ from 'tm:jQuery';
import 'tm:bootstrap';

export default {
    props: ['value', 'minuteStep', 'showSeconds', 'showMeridian'],
    mounted() {
        $(this.$el).timepicker({
            minuteStep: this.minuteStep,
            showSeconds: this.showSeconds == "true",
            showMeridian: this.showMeridian == "true"
        }).on('changeTime.timepicker', (e)=>{
            this.$emit('input', `${("" + e.time.hours).padStart(2, '0')}:${("" + e.time.minutes).padStart(2, '0')}`);
        });
        $(this.$el).timepicker("setTime", this.value);
    },

    watch: {
        value() {
            $(this.$el).timepicker("setTime", this.value);
        }
    }
}
</script>
