//!!alias widgets/vault

import LocalVault from 'tm:widgets/vault/local';
import RemoteVault from 'tm:widgets/vault/remote';

const Storages = [];
if (!!window.sessionStorage)
    Storages.push(new LocalVault("session", window.sessionStorage));
if (!!window.localStorage)
    Storages.push(new LocalVault("local", window.localStorage));
Storages.push(new RemoteVault("user"));
Storages.push(new RemoteVault("organizer"));

export default class Vault {
    constructor() {
    }

    get_namespace(name) {
        for (let source of Storages) {
            const namespace = source.get_namespace(name);
            if (!!namespace) return namespace;
        }
        return null;
    }

    async find(key, namespaces, def) {
        for (let namespace of namespaces) {
            const ns = this.get_namespace(namespace);
            if (ns === null) continue;

            const value = ns.find(key);
            if (value === null) continue;
            return value;
        }

        if (!!def) return def;

        throw new RangeError("Failed to find key.");
    }

    async set(key, namespace, value) {
        const ns = this.get_namespace(namespace);
        if (!ns) throw new ReferenceError("Failed to find namespace");
        return await ns.set(key, value);
    }
}
