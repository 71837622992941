import { exposeFunction } from "@/utils/pages";

import OptOutConfig from './thirdparty-cookies';

async function _show_cookie_law() {
    const { default: CookieBanner } = await import("./CookieBanner.svelte");
    const element = document.createElement("div");
    document.body.appendChild(element);

    const banner = new CookieBanner({
        target: element,
        props: {
            statistics: OptOutConfig.is_activated("statistics"),
            error: OptOutConfig.is_activated("error")
        }
    });

    banner.$on("submit", ({ detail }) => {
        OptOutConfig.set_activated("error", detail.error);
        OptOutConfig.set_activated("statistics", detail.statistics);
        banner.$destroy();
        element.remove();
    });
}

exposeFunction("show_cookie_law", _show_cookie_law);
if (!OptOutConfig.is_configured() && !document.querySelector('meta[name="skip-cookie-law"]')) _show_cookie_law();

export default _show_cookie_law;

