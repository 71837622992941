
<template>
    <input type="file"></input>
</template>

<script>
//!!alias widgets/inputs/file

import $ from 'tm:jQuery';
import { t } from 'tm:widgets/helpers/translations';

function readFile(file) {
    return new Promise((rs, rj) => {
        const reader = new FileReader();
        reader.onload = rs;
        reader.onerror = () => rj(null);
        reader.readAsBinaryString(file);
    })
}

export default {
    props: ['accept', 'placeholder', 'value', 'maxsize'],
    data() {
        return {
            state: 'IDLE'
        }
    },
    mounted() {
        $(this.$el).ace_file_input({
            'no_icon' : '',
            'icon_remove' : '',
            'no_file' : this.placeholder,
            'btn_choose' : 'Hochladen',
            'btn_change' : 'Ändern',
            'allowExt': this.accept.split(", "),
        });

        this.$el.addEventListener('change', async ()=>{
            try {
                const values = await this.read();
                this.state = "UPDATING";
                this.$emit('input', values);
            } catch (e) {
                this.$emit('error', e);
            }
        }, false);
    },

    methods: {
        async read() {
            return await Promise.all(Array.from(this.$el.files).map(async (file) => {
                if (!!this.maxsize && parseInt(this.maxsize) < file.size) {
                    throw t("error.file-too-large");
                }
                const result = await readFile(file);
                return {name: file.name, data: btoa(result.target.result)}
            }));
        }
    },

    watch: {
        value(v) {
            if (this.state !== "IDLE") {
                this.state = "IDLE";
                return;
            }
            $(this.$el).val("").trigger("change");
        }
    }
}
</script>
