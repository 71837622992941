<template>
    <div class="tags">
        <span class="tag" v-for="tag in value">{{ tag }}<button type="button" class="close" v-on:click="remove_tag(tag)">&times;</button></span>
        <input type="text" v-on:keydown.188.prevent="read_tag" v-on:keydown.enter.prevent="read_tag" class="form-control" v-on:blur="read_tag" v-bind:placeholder="placeholder" v-model="inp_val" :class="(!!error)?['has-error']:[]"></input>
        <span class="help-block col-xs-12 col-sm-reset inline">{{ error }}</span>
    </div>
</template>
<script>
//!!alias widgets/inputs/tags
import {remove} from 'tm:widgets/helpers/array';

export default {
    props: ['placeholder', 'value', 'error'],
    data() {
        return {
            inp_val: ""
        }
    },
    methods: {
        remove_tag(name) {
            remove(this.value, name);
        },
        read_tag() {
            if (!(this.value instanceof Array)) {
                this.value = [];
            }
            if (!this.inp_val) return;
            this.value.push(this.inp_val);
            this.inp_val = "";
        }
    }
}
</script>
