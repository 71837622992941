//!!alias vue/filters/translations

import Vue from 'tm:vue';
import { tn } from 'tm:widgets/helpers/translations';


Vue.filter('translate', function translate(value, params, plurals) {
    if (!params) params = [];
    if (!plurals) plurals = [];
    return tn(value, value, plurals, params);
});
