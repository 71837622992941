//!!alias widgets/helpers/reload

import $ from 'tm:jQuery';
import { request, build_location } from 'tm:widgets/helpers/ajax';

import SpinnerWidget from "tm:widgets/spinner";
import Flash from "tm:widgets/flash";

let reloads = [];

export async function async_reload(url) {
    if (!url) {
        url = window.location.href;
    } else {
        window.history.pushState(null, "", url);
    }
    const data = await request.get(url, {}, {dataType: "html"});
    const parsed = $(data);

    const currentReloads = reloads;
    reloads = [];

    for (let cb of currentReloads) {
        const result = cb();
        if (!!result && !!result.then) await result;
    }

    $(".main-content").replaceWith(parsed.find(".main-content"));

}

export async function reload() {
    window.location.reload();
    return new Promise(()=>{});
}

export async function redirect(url, data={}) {
    SpinnerWidget.show();
    let redirect_path;
    try {
        redirect_path = await build_location(url, data);
    } catch (e) {
        window.reportError(e);
        Flash.error();
        SpinnerWidget.hide();
        return;
    }
    window.location.href = redirect_path;
}

export async function on_async_reload(cb) {
    reloads.push(cb);
}
