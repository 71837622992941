import { exportModule } from "@/utils/pages";

exportModule("js-cookie", () => import("js-cookie"));

import "./thirdparty-cookies";
exportModule("gdpr/optout/config", () => import("./thirdparty-cookies"));

import "./ga-optout";
exportModule("gdpr/output/setting", () => import("./ga-optout"));

import "./cookie-law";
exportModule("base/cookie-law", () => import("./cookie-law"));
