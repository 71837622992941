<template>
    <div tabindex='0' class='ajaxcomplete' @focus='on_focus'>
        <div class="input-group" ref="input_root">
            <span class="input-group-addon">
                <i class="fa fa-search"></i>
            </span>
            <div class='ajaxcomplete--selected' @click='on_click_selected' v-show='state!=="selecting"'>
                <slot name='item' :value='value'></slot>
            </div>
            <input
                 tabindex='-1'
                 v-show='state==="selecting"'
                 class="form-content multiselect-search ajax-search"
    
                 @blur='on_blur'
                 @keyup.esc.stop='on_cancel'
                 @keyup.up.stop.prevent='selection(-1)'
                 @keyup.down.stop.prevent='selection(+1)'
                 @keyup.enter.stop.prevent='on_key_select'
                 
                 type='text'
                 v-model='search'
                 
                 :placeholder='placeholder'
                 ref='searchbox'
            ></input>
            <span class="input-group-btn">
                <button class="btn btn-default btn-white btn-grey multiselect-clear-filter" type="button" @click.prevent='clearSearch()'><i class="fa fa-times-circle red2"></i></button>
            </span>
        </div>
        <div class='ajaxcomplete--selection-list' v-show='state==="selecting"' :style="{width: width}">
            <promise :promise='request' @resolved='on_request_resolve' class='ajaxcomplete--selection-state'>
                <template #loading><i class='fa fa-spinner fa-spin'></i></template>
                <template #rejected><i class='fa fa-times red'></i></template>
                <template #resolved></template>
            </promise>
    
            <ul class='ajaxcomplete--selections'>
                <li class='system' v-if='choices.length === 0'>
                    <template v-if='search === ""'>{{ t('widgets.ajax-complete.no-search') }}</template>
                    <template v-else>{{ t('widgets.ajax-complete.none-found') }}</template>
                </li>
                <template v-else>
                    <li v-for='(choice, idx) in choices' :class='{active: idx===select_index}' :key="idx" @click.stop.prevent='on_mouse_select(choice)' :ref='"choice" + idx'>
                        <slot name='item' :value='choice'></slot>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
//!!alias widgets/inputs/ajax_complete

import Helpers from 'tm:widgets/helpers/etc';
import Translations from 'tm:widgets/helpers/translations';

import PromiseComponent from 'tm:widgets/inputs/promise';

export default {
    components: {Promise: PromiseComponent},
    props: [
        "value",
        "update",
        "placeholder",
        "preload"
    ],
    data() {
        return {
            request: null,
            state: "selected",
            choices: [],
            search: "",
            select_index: null,
            blurTimeoutIndex: null,
            width: 0
        }
    },

    watch: {
        search: function(newTerm) {
            if (newTerm.length > 1)
                this.request = this.update(newTerm);
        },

        value: function() {
            this.state = "selected";
        },

        state: function() {
            var _sb = this.$refs.searchbox;
            if (this.state === "selecting") {
                setTimeout(function(){_sb.focus()}, 1);
                this.search = "";
                this.choices = [];
            }
        }
    },

    methods: {
        t: Translations.t,

        on_request_resolve: function (value) {
            this.select_index = null;
            if (value !== null)
                this.choices = value || [];
        },

        on_click_selected: function() {
            this.state = "selecting";
            this.search = "";
            this.select_index = null;
        },

        on_cancel: function() {
            this.state = "selected";
        },

        on_focus: function() {
            this.state = "selecting";
            this.width = "" + this.$refs.input_root.getBoundingClientRect().width + "px";
            if (this.blurTimeoutIndex !== null) {
                clearTimeout(this.blurTimeoutIndex);
                this.blurTimeoutIndex = null;
            }
        },

        on_blur: function() {
            var self = this;
            if (this.blurTimeoutIndex === null) {
                this.blurTimeoutIndex = setTimeout(function(){
                    self.state = "selected";
                    self.blurTimeoutIndex=null;
                }, 500);
            }
        },

        clearSearch() {
            var self = this;
            this.search = "";
            this.$refs["searchbox"].focus();
            setTimeout(function(){self.on_focus()}, 100);
        },

        selection: function(up) {
            if (this.state !==  'selecting') return;
            if (this.select_index === null) this.select_index = -1;
            this.select_index = this.select_index + up;
            if (this.select_index >= this.choices.length) this.select_index = this.choices.length - 1;
            if (this.select_index < 0) this.select_index = 0;
            this.$refs["choice" + this.select_index][0].scrollIntoView(true);
        },

        on_key_select: function() {
            if (this.state !== 'selecting') return;
            if (this.select_index === null) return;
            this.$emit("input", this.choices[this.select_index]);
            this.on_blur();
        },

        on_mouse_select: function(value) {
            this.$emit("input", value);
            this.on_blur();
        }
    }
}
</script>
