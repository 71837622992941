function hide_required(element: HTMLElement) {
    if (element.hasAttribute("required")) {
        element.setAttribute("data-required", "true");
        element.removeAttribute("required");
    }

    for (let node of Array.from(element.children)) {
        if (!(node instanceof HTMLElement)) continue;
        hide_required(node);
    }
}

function show_required(element: HTMLElement) {
    if (element.getAttribute("data-required") === "true") {
        element.setAttribute("required", "required");
    }

    for (let node of Array.from(element.children)) {
        if (!(node instanceof HTMLElement)) continue;
        show_required(node);
    }
}

(window as any).forms_v2_cond_dropdown_change = (event: Event) => {
    const select = event.target as HTMLSelectElement;

    const newValue = select.value;
    const identifier = select.getAttribute("data-select-identifier");

    let base: HTMLElement = select;
    while (!base.getAttribute("data-select-owner")) base = base.parentElement!;
    base = base.parentElement!;

    for (let node of Array.from(base.querySelectorAll(`[data-target="${identifier}"]`))) {
        (node as HTMLElement).style.display = "none";
        hide_required(node as HTMLElement);
    }

    if (newValue === "") return;
    for (let node of Array.from(base.querySelectorAll(`[data-target="${identifier}"][data-value="${newValue}"]`))) {
        (node as HTMLElement).style.display = "block";
        show_required(node as HTMLElement);
    }
};

(window as any).forms_v2_cond_dropdown_rewrite_required = (identifier: string) => {
    for (let node of Array.from(document.querySelectorAll(`[data-target="${identifier}"]`))) {
        hide_required(node as HTMLElement);
    }
}
