import PromisePool from "es6-promise-pool";

export function promiseDelegate<T>(): [Promise<T>, (v: T)=>void, (r: any)=>void] {
    let done = false;
    let error: any = undefined;
    let result: T|undefined = undefined;

    let r: (v: T) => void = (v) => { done = true; result = v; };
    let j: (e: any) => void = (e) => { done = true; error = e; };

    const resultPromise = new Promise<T>((_r, _j) => {
        if (done) {
            if (error !== undefined) _j(error);
            else                     _r(result!);

            error = undefined;
            result = undefined;

            return;
        } else {
            r = _r;
            j = _j;
        }
    });

    return [
        resultPromise,
        (v) => r(v),
        (e) => j(e)
    ]
}

type _CancellableWaiter<T> = [T, (err: any) => void];


export class Channel<T> {
    private _readers: _CancellableWaiter<(v: T) => void>[] = [];
    private _writers: _CancellableWaiter<() => T>[] = [];
    private _closed: boolean = false;

    get closed(): boolean {
        return this._closed;
    }

    close() {
        if (this._closed === false) {
            const readers = this._readers;
            this._readers = [];

            const writers = this._writers;
            this._writers = [];

            readers.forEach(([_, r]) => r(new Error("Channel closed")));
            writers.forEach(([_, r]) => r(new Error("Channel closed")));
        }
        this._closed = true;
    }

    async receive(): Promise<T> {
        if (this._closed)
            throw new Error("Channel closed");

        if (this._writers.length > 0) {
            return this._writers.shift()![0]();
        } else {
            const [result, r, j] = promiseDelegate<T>();
            this._readers.push([r, j]);
            return await result;
        }
    }

    async send(value: T): Promise<void> {
        if (this._closed) {
            throw new Error("Channel closed");
        }

        if (this._readers.length > 0) {
            return this._readers.shift()![0](value);
        } else {
            const [condition, r, j] = promiseDelegate<void>();
            this._writers.push([() => {r(); return value;}, j]);
            return await condition;
        }
    }
}


export async function* callback2generator<V>(cb: (send: (value: V) => Promise<void>) => Promise<void>): AsyncGenerator<V> {
    const channel = new Channel<{state: "finish", value: null}|{state: "error", value: any}|{state: "yield", value: V}>;
    const p = (async () => {
        try {
            await cb(async (value) => {
                await channel.send({state: "yield", value});
            });
        } catch (e) {
            await channel.send({state: "error", value: e});
        }
        await channel.send({state: "finish", value: null});
    })();

    while (true) {
        const {state, value} = await channel.receive();
        if (state === "finish") {
            break;
        } else if (state === "error") {
            throw value;
        } else {
            yield value;
        }
    }

    await p;
}

export class Queue {
    private queue: Promise<void> = Promise.resolve();

    enqueue<T>(cb: () => Promise<T>): Promise<T> {
       const currentQueue = this.queue;
       const [resultPromise, r, j] = promiseDelegate<T>();

       this.queue = new Promise<void>(rs => {
            function finish() {
                rs();
            }
            currentQueue.then(() => {
                const p = cb();
                p.then(() => finish(), () => finish());
                p.then(r, j);
            });
       });

       return resultPromise;
    }
}

export class MultiQueue {
    private _pool: PromisePool<void>;
    private _channel: Channel<() => Promise<void>> = new Channel();

    constructor(slots: number) {
        this._pool = new PromisePool(() => {
            if (this._channel.closed)
                return;

            return (async () => {
                let next: () => Promise<void>;
                try {
                    next = await this._channel.receive();
                } catch (e) {return;}
                return await next();
            })();
        }, slots);
        this._pool.start().then(() => {}, window.reportError);
    }

    async enqueue<T>(cb: () => Promise<T>): Promise<T> {
        const [resultPromise, r, j] = promiseDelegate<T>();

        this._channel.send((async () => {
            const waiter = cb()
            waiter.then(r, j);
            await waiter;
        }));

        return await resultPromise;
    }

    close() { this._channel.close(); }

}


export class Reloader<T> {
    static EMPTY_PROMISE =
        (() => {
            const p = Promise.reject(new Error("Invalid state"));
            p.catch(() => {});
            return p;
        })();
    private cb: () => Promise<T>;
    private _running: boolean = false;
    private _rerun: ((v: Promise<T>) => void)[] = [];
    private _promise: Promise<T> = Reloader.EMPTY_PROMISE as Promise<T>;

    constructor(cb: () => Promise<T>) {
        this.cb = cb;
    }

    reload() {
        if (this._running) {
            const [p, rs, rj] = promiseDelegate<T>();
            this._rerun.push((new_promise) => {
                new_promise.then(rs, rj);
            });;
            return p;
        };
        const cbs = this._rerun;
        this._rerun = [];
        this._running = true;
        this._promise = this.cb();
        cbs.forEach(cb => cb(this._promise));

        const _finally = () => {
            this._running = false;
            if (this._rerun.length > 0) this.reload();
        };
        this._promise.then(_finally, _finally);
        return this._promise;
    }
}


export class SlowSignal<T> {
    private current: Promise<T>;
    private resolve: (v: T) => void;
    private resolved: boolean = false;

    constructor() {
        let reject;
        ([this.current, this.resolve, reject] = promiseDelegate());
    }

    set(v: T) {
        if (this.resolved) {
            this.unset();
        }
        this.resolve(v);
        this.resolved = true;
    }

    unset() {
        let reject;
        ([this.current, this.resolve, reject] = promiseDelegate());
    }

    get promise(): Promise<T> {
        return this.current;
    }
}


export class Joiner<T> {
    private _func: () => Promise<T>;
    private _current: {running: false}|{running: true, value: Promise<T>} = {running: false};

    constructor(func: () => Promise<T>) {
        this._func = func;
    }

    fetch(): Promise<T> {
        if (!this._current.running) {
            const op = this._func();
            const reset = () => { this._current = {running: false} };
            op.then(reset, reset);

            this._current = {running: true, value: op};
        }

        return (this._current as any).value;
    }
}


export function event(): [(cb: () => void) => void, () => void] {
    const [p, rs, _] = promiseDelegate<void>();
    return [
        (c) => p.then(() => c()),
        () => rs()
    ]
}


export type Timeout = ReturnType<typeof window.setTimeout> | ReturnType<typeof setTimeout>;
