import { ShopConnector } from "./api";
import { SeatingChart as RawSeatingChart, Seat as RawSeat, PriceData } from "./connector";
import { Category } from "./event";

function sorted<T>(t: T[], key: (t: T) => number): T[] {
  const result = [...t];
  result.sort((a, b) => {
    const kA = key(a);
    const kB = key(b);
    return kA < kB ? 1 : ((kB < kA) ? -1 : 0);
  });
  return result;
}

export enum FocusPointType {
    PUSH,
    PULL
}

const imageCache = new Map<string, Promise<HTMLImageElement>>();
async function getChartImage(_chart: RawSeatingChart) {
    if (!imageCache.has(_chart.background_url)) {
        imageCache.set(_chart.background_url, new Promise((rs, rj) => {
            const image = document.createElement("img");
            image.onload = () => {
                rs(image);
            };
            image.onerror = (reason) => {
                rj(reason);
            }
            image.src = _chart.background_url;
        }));
    }

    return await imageCache.get(_chart.background_url)!;


}

/**
 * Represents a seating chart.
 */
export class Chart {
  private connector: ShopConnector;
  private chartData: RawSeatingChart;
  private event_id: number;

  constructor(connector: ShopConnector, chart: RawSeatingChart, event_id: number) {
    this.connector = connector;
    this.chartData = chart;
    this.event_id = event_id;
  }

  async getChartSize(): Promise<{width: number, height: number}> {
    try {
      const image = await getChartImage(this.chartData);
      return {width: image.width, height: image.height}
    } catch (e) {
      console.error(e);
      const bbox = this.chartData.bbox;
      return {width: bbox.width+20, height: bbox.width+20};
    }
  }

  async getFocusPoint(): Promise<{ direction: FocusPointType, x: number, y: number }> {
    const {width, height} = await this.getChartSize();
    const {dx, dy, anchor: {x, y}, type} = this.chartData.focus; 

    let rx = dx;
    let ry = dy;

    switch (x) {
      case "left":
        rx += 0;
        break;
      case "center":
        rx += width / 2;
        break;
      case "right":
        rx += width;
        break;
    }

    switch (y) {
      case "top":
        ry += 0;
        break;
      case "middle":
        ry += height / 2;
        break;
      case "bottom":
        ry += height;
        break;
    }

    return {direction: type === "PUSH" ? FocusPointType.PUSH : FocusPointType.PULL, x: rx, y: ry};
  }

  /**
   * Generates a list of blocks.
   */
  async* getBlocks(): AsyncGenerator<ChartBlock> {
    for (let blockId of this.chartData.blocks) {
      yield await this.getBlock(blockId);
    }
  }

  /**
   * Loads a specific block.
   * @param id   The ID of the block
   */
  async getBlock(id: number): Promise<ChartBlock> {
    if (this.chartData.blocks.findIndex(b => b == id) < 0)
      throw new Error("Couldn't find block");

    const seats = await this.connector.getSeats(this.event_id, id);
    return new ChartBlock(this.connector, this.event_id, id, seats);
  }

  
  /**
   * Selects the best seat
   * @param near 
   * @param set 
   */
  *findBestSeats(near: Position, set: ChartSeat[], direction: FocusPointType = FocusPointType.PULL): Generator<ChartSeat> {
    type Candidate = { dsq: number, seat: ChartSeat};
    const sq = (n: number) => n*n;

    const seats: Candidate[] = sorted(
        set.map(s => ({dsq: sq(near.x - s.x) + sq(near.y - s.y), seat: s})),
        (d) => (direction === FocusPointType.PULL ? -1 : 1) * d.dsq
    );
    for (let seat of seats) {
      if (seat.seat.status !== "FREE") continue;
      yield seat.seat;
    }
  }
}


interface SeatContainer {
  getSeats(): ChartSeat[];
}

interface Position {
  x: number,
  y: number
}


export class ChartBlock implements SeatContainer {
  private connector: ShopConnector;
  private event_id: number;
  private block_id: number;
  private seats: RawSeat[];

  constructor(connector: ShopConnector, event_id: number, block_id: number, seats: RawSeat[]) {
    this.connector = connector;
    this.event_id = event_id;
    this.block_id = block_id;
    this.seats = seats;
  }

  getRow(rowId: number): ChartRow {
    return new ChartRow(this.connector, this.event_id, this.block_id, rowId, this.seats.filter(s => s.row_id == rowId));
  }

  getRows(): ChartRow[] {
      const rows = new Map<number, RawSeat[]>();
      for (let seat of this.seats) {
          let seatList;
          rows.set(seat.row_id, seatList = rows.get(seat.row_id)||[]);
          seatList.push(seat);
      }

      return (
          Array.from(rows.entries())
          .map(([k, v]) => new ChartRow(this.connector, this.event_id, this.block_id, k, v))
      );
  }

  getSeats(): ChartSeat[] {
    return this.seats.map(s => new ChartSeat(this.connector, s, this.event_id, this));
  }
}


export class ChartRow implements SeatContainer {
  private connector: ShopConnector;
  private event_id: number;
  private block_id: number;
  private row_id: number;
  private seats: RawSeat[];

  constructor(connector: ShopConnector, event_id: number, block_id: number, row_id: number, seats: RawSeat[]) {
    this.connector = connector;
    this.event_id = event_id;
    this.block_id = block_id;
    this.row_id = row_id;
    this.seats = seats;
  }

  get id(): number {
    return this.row_id;
  }

  async getBlock(): Promise<ChartBlock> {
    const seats = await this.connector.getSeats(this.event_id, this.block_id);
    return new ChartBlock(this.connector, this.event_id, this.block_id, seats);
  }

  getSeats(): ChartSeat[] {
    return this.seats.map(s => new ChartSeat(this.connector, s, this.event_id));
  }
}


export class ChartSeat implements Position {
  private connector: ShopConnector;
  private data: RawSeat;
  private __block: ChartBlock|undefined;
  private _event_id: number;

  constructor(connector: ShopConnector, data: RawSeat, event_id: number, block?: ChartBlock) {
    this.connector = connector;
    this.data = data;
    this._event_id = event_id;
    this.__block = block;
  }

  async getBlock(): Promise<ChartBlock> {
      if (this.__block !== undefined) return this.__block;
    const seats = await this.connector.getSeats(this.event_id, this.data.block_id);
    return new ChartBlock(this.connector, this.event_id, this.data.block_id, seats);
  }

  async getRow(): Promise<ChartRow> {
    return (await this.getBlock()).getRow(this.data.row_id);
  }

  async getCategory(): Promise<Category> {
    const cpd: PriceData = await this.connector.getEvent(this._event_id);
    return new Category(this._event_id, cpd.categories.find(c => c.id === this.data.category_id)!, this.connector);
  }

  get status() {
    return this.data.status;
  }

  get event_id() {
    return this._event_id;
  }

  get id() {
    return this.data.id;
  }

  get x() {
    return this.data.x;
  }

  get y() {
    return this.data.y;
  }
}
