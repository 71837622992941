import { addInterceptor } from "@/_error";

addInterceptor(100, (_, extras: Record<string, any>) => {
    extras["side"] = "frontend";
    return false;
});


import("env:organizer_info").then(({organizer_id}) => {
    addInterceptor(100, (_, extras: Record<string, any>) => {
        extras["organizer"] = organizer_id;
        return false;
    });
});
