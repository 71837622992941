import { PaidBooking } from "./connector";

export class Booking {
    private _pb: PaidBooking

    constructor(pb: PaidBooking) {
        this._pb = pb;
    }

    get id() {
        return this._pb.id;
    }

    get ticket_url() {
        return this._pb.ticket_url;
    }

    get receipt_url() {
        return this._pb.receipt_url;
    }
}
