export function openPopup() {
    const _window = window.open("", "", "width=600,height=400,status=yes,scrollbars=yes,resizable=yes");
    for (let child of window.document.head.children) {
      const newNode = child.cloneNode(true);
      if (newNode instanceof HTMLLinkElement && newNode.href.indexOf("//") < 0) {
        newNode.href = `${window.location.protocol}://${window.location.host}${newNode.href}`;
      }
      _window.document.head.appendChild(newNode);
    }
    _window.focus();
    return _window;
}