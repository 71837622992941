import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { byteify } from "@/utils/json";
import { Queue } from "@/utils/promises";
import fetchAdatper from "@/vendor/axios-fetch-adapter";
import { addInterceptor } from "@/_error";


function makeClient(config: AxiosRequestConfig): AxiosInstance {
    const cl = axios.create(config); 

    cl.interceptors.request.use((req) => {
        const token = (<HTMLMetaElement>document.querySelector('meta[name="csrf-token"]'))?.content;
        req.headers["X-CSRF-Token"] = token;
        if (req.headers["Accept"] === undefined) {
            req.headers["Accept"] = "application/json, text/plain;q=0.5; */*;q=0.3"
        }
        return req;
    });
    cl.interceptors.request.use(async (req) => {
        if (["head", "HEAD", "get", "GET"].map(t => req.method === t).filter(t=>t).length > 0)
            return req;

        const contentType = req.headers["content-type"] || req.headers["Content-Type"];

        if (req.data instanceof FormData) {
            return req
        } else if (contentType === undefined || contentType.startsWith("application/json")) {
            delete req.headers["content-type"];
            delete req.headers["Content-Type"];
            req.headers["Content-Type"] = "application/json";

            // Otherwise just dumbly stringify it.
            req.data = await byteify(req.data);
        }

        return req;
    });
    return cl;
}

const client = makeClient({adapter: fetchAdatper});
export default client;

export const xhrClient = makeClient({})

export function replaceURLParams(url: string, params: {[key: string]: any}) : string {
    for (let param of Object.keys(params)) {
        url = url.split(`:${param}`).join(encodeURIComponent("" + params[param]));
    }
    return url;
}


///////////////////////////////////////////////////////77
// Image conversion technique.

function canUseOffscreenCanvas(): boolean {
    const w = window as any;
    if (!w["OffscreenCanvas"]) return false;
    if (!w["createImageBitmap"]) return false;

    const cv = new w.OffscreenCanvas(1, 1) as HTMLCanvasElement;
    if (cv.getContext("2d") === null) return false;

    return true;
}

function canUseImageBitmapInProcess(): boolean {
    const w = window as any;
    if (!w["createImageBitmap"]) return false;
    
    const cv = document.createElement("canvas");
    if (cv.getContext("bitmaprenderer") === null) return false;

    return true;
}

function inProcessRenderer(blit: (canvas: HTMLCanvasElement, b: File) => Promise<() => void>): (b: File) => Promise<Blob> {
    return async (blob: File) => {
        const canvas = document.createElement("canvas");
        const cleanup = await blit(canvas, blob);

        try {
            return await new Promise<Blob>((rs, rj) => canvas.toBlob(
                (b: Blob|null) => b !== null ? rs(b) : rj(), 
                "image/png"
            ));
        } finally {
            cleanup();
        }

    }
}

const dumbRenderer = inProcessRenderer(async (canvas, blob) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("Cannot acquire 2D context for canvas.");

    const image = document.createElement("img");
    await new Promise<void>((rs, rj) => {
        image.onload = () => {
            rs();
        };
        image.onerror = (err) => {
            rj(err);
        };
        image.src = URL.createObjectURL(new Blob([blob]));
    });

    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0);

    return () => {
        URL.revokeObjectURL(image.src);
    }
});

const bitmapRenderer = inProcessRenderer(async (canvas, blob) => {
    const bitmap = await window.createImageBitmap(blob);
    const ctx = canvas.getContext("bitmaprenderer");
    if (!ctx) throw new Error("Cannot acquire Bitmap-Renderer for canvas.");
    ctx?.transferFromImageBitmap(bitmap)

    return () => {
        bitmap.close();
    };
});



let worker: Worker|null = null;
const workerQueue = new Queue();
async function workerRenderer(blob: File): Promise<Blob> {
    const rawImage = await blob.arrayBuffer();

    return workerQueue.enqueue(async () => {
        if (worker === null) {
            worker = new Worker(new URL("./_workers/img2png", import.meta.url));
        }

        const result = await new Promise<ArrayBuffer>((rs, rj) => {
            worker!.addEventListener("message", (msg) => {
                const data: ArrayBuffer|null = msg.data;
                if (data === null) {
                    rj(new Error("Conversion failed in Web-Worker."));
                } else {
                    rs(msg.data);
                }
            }, {once: true});

            (worker!.postMessage as any)({rawImage, type: blob.type}, [rawImage]);
        });

        const data = new Response(result, {headers: {"Content-Type": "image/png"}});
        return await data.blob();

    });
}

const imageRenderer = 
    canUseOffscreenCanvas()
    ? workerRenderer
    : (canUseImageBitmapInProcess() ? bitmapRenderer : dumbRenderer);

export async function fileList2DataUrl(fl: FileList): Promise<string> {
    const file = fl[0];
    const blob = await imageRenderer(file);
    return new Promise<string>((rs, rj) => {
        const reader = new FileReader();
        reader.onload = () => rs(reader.result as string);
        reader.onerror = () => rj(reader.error);
        reader.onabort = () => rj(new Error("Read aborted..."));
        reader.readAsDataURL(blob);
    });

}


export function addToSearchParams(params: URLSearchParams, q: Record<string, any>) {
    const newParams = new URLSearchParams(params);

    function _toQS(key: string, obj: any) {
        if (typeof obj === "number" || typeof obj === "string") {
            newParams.append(key, obj.toString());
        } else if (obj instanceof Array) {
            if (obj.findIndex(o => !(typeof o === "number" || typeof o === "string")) < 0) {
                obj.forEach(o => _toQS(`${key}[]`, o));
            } else {
                obj.forEach((o, i) => _toQS(`${key}[${i}]`, o));
            }
        } else {
            Object.keys(obj).forEach(n => _toQS(`${key}[${n}]`, obj[n]));
        }
    }

    Object.keys(q).forEach(e => _toQS(e, q[e]));
    return newParams;
}

addInterceptor(100, (err: any, extras: Record<string, any>) => {
    if (err?.isAxiosError) {
        const error = err as AxiosError;
        const req = error.request;

        extras["request-url"] = req.url?.toString()
    }
    return false;
});

addInterceptor(100, (err: any, extras: Record<string, any>) => {
    if (err?.isAxiosError) {
        const error = err as AxiosError;

        if (!!error.response?.headers.get("x-runtime")) {
            extras["runtime"] = error.response?.headers.get("x-runtime");
        }
        if (error.response !== null) {
            extras["status"] = error.response!.status;
            extras["statusText"] = error.response!.statusText;
            extras["request-id"] = error.response?.headers.get("x-request-id");
        }
    }
    return false;
});

addInterceptor(1000, (err: any) => {
    if (err?.isAxiosError) {
        const error = err as AxiosError;
        console.group("A request failed:")

        const text = [];

        text.push(`${error.request?.method} ${error.request?.url}`);
        if (error.response !== null) {
            text.push(`${error.response!.status} ${error.response!.statusText} - ID: ${error.response?.headers.get("x-request-id")}`);
        }
        if (!!error.response?.headers.get("x-runtime")) {
            text.push(`Request took: ${error.response?.headers.get("x-runtime")}s`);
        }

        if (error.response?.headers["Content-Type"] == "application/json") {
            const responseData = error.response?.data;
            if (responseData["error"] !== null) {
                text.push(`Error-Message: ${responseData["error"]}`);
            }
            if (responseData["code"] !== null) {
                text.push(`Error-Code: ${responseData["code"]}`);
            }
        }

        console.error(text.join("\n"));
        
        console.groupEnd();
        return true;
    }

    return false;
});
