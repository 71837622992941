import { ShopConnector } from "./api";
import { Bundle } from "./bundles";
import { ChartSeat } from "./chart";
import { Permit, Signal, Snapshot, SnapshotItem, UnseatedOptions, UnseatedTicket } from "./connector";
import { CouponApplicationFailed } from "./errors";
import { Category, Discount } from "./event";
import { Translator } from "./translations";
import { derived, get, simple } from "./utils/signals";

function noop(){};




/**
 * This clock is capable of detecting whether or not the tab is visible,
 * and adjust its interval accordingly.
 *
 * A clock doesn't need to be that accurate if it isn't visible.
 */
const clock: Signal<Date> = simple((set) => {
    set(new Date());

    let interval: number = -1;
    const VISIBLE_LENGTH = 500;
    const HIDDEN_LENGTH = 15000;

    function resetInterval() {
        if (interval >= 0) clearInterval(interval);
        const length = document.visibilityState == "visible" ? VISIBLE_LENGTH : HIDDEN_LENGTH;
        interval = setInterval(() => { set(new Date()); }, length);
        set(new Date());
    }
    resetInterval();
    document.addEventListener("visibilitychange", resetInterval);

    return () => {
        clearInterval(interval);
        document.removeEventListener("visibilitychange", resetInterval);
    }
});


export type SellableId = number;


export class Cart {
    private _snapshot: Signal<Snapshot>;
    private _connector: ShopConnector;
    private _translator: Translator<any>;

    constructor(connector: ShopConnector, translator: Translator<any>) {
        this._snapshot = connector.whenCartUpdated();
        this._connector = connector;
        this._translator = translator;
    }

    async reload() {
        await this._connector.ensureReloadCart();
    }

    async getForms(): Promise<Map<number, Record<string, string>>> {
        return await this._connector.showForms();
    }

    /**
     * Checks the validity of the coupon code.
     */
    async checkCouponCode(code: string): Promise<{success: boolean, reason: {code: string, text: string}}> {
        const {success, reason} = await this._connector.checkCouponCode(code);

        return {
            success,
            reason: {
                code: reason,
                text: this._translator(`webshop.errors.coupons.${reason}`)
            }
        }
    }

    /**
     * Applies the coupon code to the cart.
     */
    async applyCouponCode(code: string): Promise<void> {
        const {success, reason} = await this._connector.applyCouponCode(code);
        if (!success) {
            throw new CouponApplicationFailed(reason, this._translator(`webshop.errors.coupons.${reason}`));
        }
    }

    /**
     * Lists all available bundles.
     */
    async getBundles(): Promise<Bundle[]> {
        return (await this._connector.listBundles({})).map(rebate => new Bundle(this._connector, this._translator, rebate));
    }

    /**
     * Returns the bundle with the given name
     */
    async getBundle(id: string): Promise<Bundle|null> {
        const multiResult = await this._connector.listBundles({id});
        const result = multiResult.find(r => r.id === id);
        if (result === null) return null;
        return new Bundle(this._connector, this._translator, result);
    }

    /**
     * Adds a rebate card.
     * The exact type is given as a key.
     */
    async addRebateCard(key: string, count: number = 1): Promise<void> {
        await this._connector.buyRebateCard(key, count);
    }

    /**
     * Adds a gift-cart to the cart.
     * The amount is in cents.
     */
    async addGiftCard(amount: number, count: number = 1): Promise<void> {
        await this._connector.buyGiftCard(amount, count);
    }

    /**
     * Returns the raw snapshot items.
     */
    get items(): Signal<SnapshotItem[]> {
        return derived(this._snapshot, ($snapshot) => $snapshot.items);
    }

    /**
     * This callback is called when all changes have been sent to the server.
     */
    async onComplete(cb: () => void) {
        await this._connector.onTransactionCompleted(cb);
    }

    /**
     * Shows the sellable-ids that currently match the given UnseatedTicket.
     */
    matching(ticket: Category|Discount): Signal<Permit[]> {
        const t = ticket.__ticket();

        return derived(this.items, ($items) => $items.filter(item => {
            if (item.type !== "permit") return false;

            const permit: Permit = item as Permit;
            if (permit.event_id !== t.event) return false;
            if (permit.category_id !== t.category) return false;
            if (!!t.discount && permit.base_discount_id !== t.discount) return false;

            return true;
        })) as Signal<Permit[]>;
    }

    /**
     * Returns the total price of the given snapshot.
     */
    static snapshotTotal(items: Signal<SnapshotItem[]>): Signal<number> {
        return derived(items, ($item) => $item.map(item => item.price).reduce((p, n) => p+n, 0));
    }

    /**
     * Returns the total vat of the given snapshot.
     */
    static snapshotVat(items: Signal<SnapshotItem[]>): Signal<number> {
        return derived(items, ($item) => $item.map(item => item.vat_price).reduce((p, n) => p+n, 0));
    }

    /**
     * Adds a ticket to the cart.
     * This function is already deprecated. Use addUnseated instead.
     */
    async add(ticket: Category|Discount, count: number = 1, options: Partial<UnseatedOptions> = {}): Promise<void> {
        await this._connector.addUnseatedPermit(ticket.__ticket(), count, options);
    }

    /**
     * Updates the options on a specific sellable.
     */
    async updateOptions(id: number, options: UnseatedOptions): Promise<void> {
        await this._connector.updateSellable(id, null, options);
    }

    /**
     * Updates the discount of a seat.
     */
    async updateDiscount(id: number, discount: Discount|null): Promise<void> {
        const data = await get(this.items)
        const sellable = data.find(i => i.id === id) as Permit|null;
        if (sellable !== null) throw Error("Could not find sellable...");
        await this._connector.updateSellable(id, {
            event: sellable.event_id, category: sellable.category_id, discount: discount?.id
        }, null);
    }

    /**
     * Selects the seat and puts it into the cart.
     *
     * @param seats     The seats to sell at this discount
     * @param discount  The discount to apply to each seat
     */
    async addSeat(seat: ChartSeat, discount: Discount|null): Promise<void> {
        await this._connector.blockSeat(seat.event_id, seat.id, discount?.id || null);
    }

    /**
     * Removes a selected seat from the cart
     * @param seat      The seat to remove from the cart.
     */
    async removeSeat(seat: ChartSeat): Promise<void> {
        await this._connector.unblockSeat(seat.event_id, seat.id);
    }

    /**
     * Removes a sellable by its id.
     * You can obtain an ID by either using matchingIds, or inspecting items()->id
     */
    async remove(id: number): Promise<void> {
        await this._connector.removeSellable(id);
    }

    /**
     * Returns the price of a ticket.
     */
    async priceOf(ticket: UnseatedTicket): Promise<number> {
        return await this._connector.priceFor(ticket);
    }

    /**
     * Returns the date when the cart is going to expire or null if it won't expire.
     */
    get expiresAt(): Signal<Date|null> {
        return derived(this._snapshot, ($snapshot) => {
            return $snapshot.expiry_time;
        });
    }

    /**
     * Returns the time in seconds until the cart is going to expire.
     * Subscribing to the signal will between every 500ms or 15seconds.
     * This can be used as a countdown-source.
     */
    get expiresIn(): Signal<number|null> {
        return simple((set) => {
            let clockUnsubscribe: (()=>void)|null = null;

            let lastSnapshot: Snapshot = {expiry_time: null, items: []};
            let alreadyElapsed = false;

            const snapshotUnsubscribe = this._snapshot.subscribe(snapshot => {
                lastSnapshot = snapshot;
                let now = new Date();

                if (snapshot.expiry_time === null) {
                    (clockUnsubscribe||noop)();
                    clockUnsubscribe = null;
                    set(null);
                    return;
                }

                function recalculateTime() {
                    const expires_at: Date = lastSnapshot.expiry_time!;
                    const dx = expires_at.getTime() - now.getTime();

                    if (dx < 0) {
                        if (alreadyElapsed) return;
                        alreadyElapsed = true;
                    } else {
                        alreadyElapsed = false;
                    }
                    set(dx);
                }
                recalculateTime();

                if (!clockUnsubscribe) {
                    clockUnsubscribe = clock.subscribe((d) => {
                        now = d;
                        recalculateTime();
                    });
                }
            });

            return () => {
                snapshotUnsubscribe();
                (clockUnsubscribe||noop)();
            }
        });
    }
}
