//!!alias widgets/inputs/promise

import { bound } from 'tm:widgets/helpers/etc';

export default {
    render: function (h) {
        var _slot = this.$scopedSlots[this.state];

        if (!_slot) _slot = this.$scopedSlots.loading;
        if (!_slot) _slot = function(_){return [];};
        _slot = _slot({value: this.value});

        return h(this.element, [_slot]);
    },

    props: {
        promise: {
            type: null,
            required: true
        },

        log: {
            type: Boolean,
            default: false
        },

        element: {
            type: [String, Object],
            default: "div"
        }
    },

    data: function() {
        return {
            state: "unset",
            value: null,
            counter: 0
        }
    },

    created: function mounted(){
        this.rebindPromise(this.promise);
    },

    beforeDestroy: function destroyed() {
        this.counter = -1;
    },

    methods: {
        rebindPromise: function update(newPromise) {
            var new_id = ++this.counter;
            this.state = "loading";
            this.value = null;
            this.$emit("reset", newPromise);

            var resolve = bound(this, function(r) {
                if (new_id !== this.counter) return;

                this.$emit("resolved", r);
                this.value = r;
                this.state = "resolved";
            });
            var reject = bound(this, function(e) {
                if (this.log) window.reportError(e);
                if (new_id !== this.counter) return;

                this.$emit("rejected", e);
                this.value = e;
                this.state = "rejected";
            });

            if (!!newPromise && typeof newPromise.then === "function") {
                newPromise.then(resolve, reject);
            } else if (typeof newPromise === 'function') {
                try {
                    resolve(newPromise());
                } catch (e) {
                    reject(e);
                }
            } else {
                resolve(newPromise);
            }
        }
    },

    watch: {
        promise: function(newPromise) {
            this.rebindPromise(newPromise);
        }
    }
}
