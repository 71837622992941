<template>
    <div>
        <input type="text" v-model="inp_val" @input="updateInput" class="form-control" :class="(!!error)?['has-error']:[]" role="be_ev_ml_ev_name"/></input>
        <span class="help-block col-xs-12 col-sm-reset inline" v-if="error">{{ error }}</span>
    </div>
</template>

<script>
//!!alias widgets/inputs/text_input
export default {
    props: ['value', 'error'],
    data() {
        return {
            inp_val: this.value
        }
    },
    methods: {
        updateInput(e) {
            this.$emit('input', this.inp_val);
        }
    },

    watch: {
        value(new_val) {
            this.inp_val = new_val;
        }
    }
}
</script>
