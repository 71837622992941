<template>
    <label class="block v-ace-switch-6">
        <span v-if="!!$slots.before" class="ace-switch-6-span-left"><slot name="before"></slot></span>
        <input class="ace ace-switch ace-switch-6" type="checkbox" :checked="checked" @change="$emit('change', $event.target.checked)"></input>
        <span class="lbl"></span>
        <span v-if="!!$slots.default"><slot></slot></span>
    </label>
</template>

<script>
//!!alias widgets/inputs/switch

export default {
    model: {
        prop: "checked",
        event: "change"
    },
    props: {'checked': Boolean}
};
</script>