//!!alias widgets/helpers/dialogbox

import Translations from 'tm:widgets/helpers/translations';
import { PromiseDelegate } from 'tm:widgets/helpers/promise';
import Modal from 'tm:widgets/bootstrap/modal';
import $ from 'tm:jQuery';

function _make_modal(title) {
    return $('\
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">\
        <div class="modal-dialog" role="document">\
            <div class="modal-content">\
                <div class="modal-header" ref="head">\
                    <h5>' + title + '</h5>\
                </div>\
                <div class="modal-body" ref="body">\
                    \
                </div>\
                <div class="modal-footer" ref="footer">\
                </div>\
            </div>\
        </div>\
    </div>\
    ');
};

export async function askyesno(title, question) {
    const _modal = _make_modal(title, question);
    const _yes = $("<button type=\"button\" class=\"btn btn-primary\" data-dismiss=\"modal\">" + Translations._("dialog.yes") + "</button>");
    const _no = $("<button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">" + Translations._("dialog.no") + "</button>");

    _modal.find('[ref="body"]').append(question);
    _modal.find('[ref="footer"]').append(_yes);
    _modal.find('[ref="footer"]').append(_no);

    $("body").append(_modal);

    var promise = new PromiseDelegate();
    var _bmodal = new Modal(_modal);

    var _finished = false;
    var _finish = async (result) => {
        if (!!_finished) return;

        _finished = true;
        await _bmodal.hide();
        _modal.remove();

        promise.resolve(result);
    };

    // _modal.on("hidden.bs.modal", function(){_finish(false);});
    _yes.click(function(){ _finish(true);});
    _no.click(function(){ _finish(false);});

    await _bmodal.show();
    return await promise.promise();
}
