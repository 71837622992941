import { event } from "@/utils/promises";

export interface CustomElement {
    apply(tag: HTMLUnknownElement): () => void;
}


export default class ComponentSet {
    map: Map<string, () => Promise<{default: CustomElement}>> = new Map();
    loaded: Set<string> = new Set();

    set(name: string, element: () => Promise<{default: CustomElement}>) {
        if (this.loaded.has(name)) { return; }
        this.map.set(name, element);
    }

    async load(name: string, element: HTMLUnknownElement) {
        const loader = this.map.get(name)!;
        const module = await loader()

        const observer = new MutationObserver((events) => {
            for (let event of events) {
                if (Array.from(event.removedNodes).includes(element)) {
                    setUnloaded();
                    observer.disconnect();
                };
            }
        });
        observer.observe(element, {childList: true});

        const [onUnloaded, setUnloaded] = event();
        try {
            const unloader = module.default.apply(element);
            onUnloaded(() => {
                try {
                    unloader()
                } catch (e) {
                    window.reportError(e);
                }
            });
        } catch (e) {
            window.reportError(e);
        }
    }

    _autodiscover(scope: HTMLElement) {
        (window["requestIdleCallback"] || window["requestAnimationFrame"])(() => {
            let candidates: Element[] = [scope, ...Array.from(scope.querySelectorAll("*"))];
            for (let el of candidates) {
                let tag = el.localName;
                if (tag.includes("-") && this.map.has(tag)) {
                    this.load(tag, el as HTMLUnknownElement).catch(window.reportError);
                }
            }
        });
    }

    autodiscover(scope: HTMLElement) {
        this._autodiscover(scope);

        const observer = new MutationObserver((list) => {
            for (let { addedNodes, type } of list) {
                if (type !== "childList") continue;
                for (let node of Array.from(addedNodes).filter(n => n instanceof HTMLElement)) {
                    this._autodiscover(node as HTMLElement);
                }
            }
        });
        observer.observe(scope, {childList: true, subtree: true});
    }
}
