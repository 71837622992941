<template>
  <input type="text" @focus="enter" @blur="exit" v-model="displayValue"></input>
</template>
<script>
//!!alias widgets/inputs/number
import Translations from 'tm:widgets/helpers/translations';

export default {
  props: ['value', 'precision', 'unit'],

  data: function() {
    return {
      current: "",
      blurred: true
    }
  },

  methods: {
    enter: function() {
      this.blurred = false;
      this.current = Translations.l(this.value);
    },
    exit: function() {
      this.blurred = true;
      this.current = "";
    },

    toActualNumber: function toReadableNumber(n) {
      var result = Translations.parseNumber(n);
      if (isNaN(result)) result = 0;
      var precision = parseInt(this.precision||2);
      result = Math.round(result * (10**precision))/(10**precision);
      return result;
    }
  },

  computed: {
    displayValue: {
      get: function() {
        if (this.blurred) {
          return Translations.l(this.value, {
              minimumFractionDigits: parseInt(this.precision||2),
              maximumFractionDigits: parseInt(this.precision||2)
          }) + (this.unit||"")
        } else {
          return this.current;
        }
      },

      set: function(value) {
        this.current = value;
        var num = this.toActualNumber(value);
        this.$emit("input", num);
      }
    },
  }
};

</script>
