import { onLoaded } from "@/utils/html";
import client from "@/utils/http";
import ComponentSet from "@frontend/component_set";
import StateRegistry from "@frontend/state";


export const state = new StateRegistry();
export const components = new ComponentSet();

onLoaded(() => {
    components.autodiscover(document.body);
});


let settingsProvider: (name: string) => Promise<any> = () => { throw new Error("No settings provided provided."); };

export function setProvider(provider: (name: string) => Promise<any>) {
    settingsProvider = provider;
}

export async function custom_setting<T>(name: string): Promise<T> {
    return settingsProvider(name);
}

